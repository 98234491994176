.makeStyles-tabRootButton-86{
    font-size: 20px !important;
}
.makeStyles-blueCardHeader-98 {
    color: rgb(0, 0, 0) !important;
    background: rgb(4, 35, 92) !important;
}

.makeStyles-description-81 {
    color: rgb(0, 0, 0) !important;
}
